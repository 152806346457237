@import url('https://fonts.googleapis.com/css?family=Lato');

#hamburgerCartMenu1{
    display:none;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.clip-board{
    position:absolute;
    opacity:0;
    pointer-events:none;   
}

.wrapper{
    width:100vw;
    height:100vh;
}

.content{
    width:90%;
    margin: 0px auto;
}

.header{
    width:100%;
    height:70px;
}

.menu{
    position:relative;
    width:100%;
    height:40px;
    border-bottom:solid 1px #B59343;
}

.title{
    font-family: ConduitITCStd-ExtraBold;
    font-size: 40px;
    color: #000000;
    letter-spacing: -0.38px;
    position:absolute;
    top:5px;
    right:3px;
}

#roleWrapper{
    position:absolute;
    top:5px;
    left:0px;  
}

#locWrapper{
    position:absolute;
    top:5px;
    left:170px;
}

.dropdownWrapper{
    width:160px;
    background:#ffffff;
    border:solid 1px #333333;
    overflow:hidden;
    z-index:100;
}

#roleTitle, #locTitle{
    width:100%;
    height:20px;
    padding: 2px 5px;
    font-family: Lato;
    font-size: 12px;
    color: #000000;
    letter-spacing: 0;
    border-bottom:solid 1px #333333;
    cursor:pointer;
    
}

.dropdownTableDiv{
    width:100%;
    /*height:180px;*/
    overflow:hidden;
    background:#ffffff;
    overflow-y:scroll;
    
}

.closed{
    height:20px;
    }

.open{
    height:auto;
    
    }

.dropdownTable{
    width:100%;
    height:auto;
    background:#ffffff;
}

.dropdownRow {
    width:100%;
    height:40px;
}

.dropdownRow {
    background-color:#ffffff;
    font-family: Cormorant-SemiBold;
    font-size: 12px;
    color: #000000;
    letter-spacing: 0;
}

.dropdownRow:nth-child(odd) {
    background-color:#f6f6f6; 
}

.dropdownRow:hover {
    background-color:#B59343;
    color:#ffffff;
}

.dropdownRow td{
    padding: 2px 5px;
    font-family: Georgia-Italic;
    font-size: 12px;
    
    letter-spacing: 0;
}

.reset{
    position:absolute;
    top:6px;
    left:340px;
}

.search{
    position:absolute;
    top:5px;
    left:400px;
}

.resultsHeader{
    position:relative;
    width:100%;
    height:100px;
    
}

.bread_crumbs{
    position:absolute;
    left:0px;
    top:60px;
    
    height:22px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
}

.search_results{
    position:relative;
    top:50%;
    transform: translateY(-50%);
    font-family: "Muli";
    font-size: 13px;
    color: #000000;
    letter-spacing: -0.03px;
    line-height:13px;
    height:13px;
}

.param_wrapper{
    position:relative;
    margin-left:10px;
    background-color:#F6F6F6;
    height:100%;
    padding:0px 20px;
    display:none;
}

#search_parameter1{
    position:relative;
    top:50%;
    transform: translateY(-50%);
    font-family: "Muli";
    font-size: 10px;
    color: #000000;
    letter-spacing: -0.02px;
    line-height:10px;
    height:10px;
}

#search_parameter2{
    position:relative;
    top:50%;
    transform: translateY(-50%);
    font-family: "Muli";
    font-size: 10px;
    color: #000000;
    letter-spacing: -0.02px;
    line-height:10px;
    height:10px;
}

.hits{
    position:relative;
    top:50%;
    transform: translateY(-50%);
    font-family: "Muli";
    font-size: 13px;
    color: #000000;
    letter-spacing: -0.03px;
    line-height:13px;
    height:13px;
    margin-left:10px;
    display:none;
}

.resultsCartWrapper{
    position:relative;
}

.results{
    position:absolute;
    left:0px;
    top:0px;
    width:100%;
    /*width:74%;*/
    height:75vh;
    overflow-y:scroll;
    overflow-x:none;
    -webkit-transition: width 0.5s;
    transition: width 0.5s;
}



.tableResults{
    width:99%;
}

.headerRow{
    height:30px;
}

.headerRow td{
    text-align:center;
    font-family: "Muli";
    font-size: 13px;
    color: #000000;
    letter-spacing: 0;
}

.headerRow td:nth-child(1){
    width:20px;
}

.headerRow td:nth-child(2){
    width:20px;
}

.headerRow td:nth-child(3),.headerRow td:nth-child(4),.headerRow td:nth-child(5){
    width:100px;
}

.headerRow td:nth-child(6),.headerRow td:nth-child(7){
    width:200px;
}

.headerRow td:nth-child(9){
    width:20px;
}

.headerRow td:nth-child(n+2){
    border-top: solid 1px #333333;
    border-bottom: solid 1px #333333;
}

.hamburgerHead{
    width:15px;
    height:30px;
    margin:0px 5px 0px 25px;
    background: url('../img/hamburger.png') no-repeat left;
    overflow:hidden;
    cursor:pointer;
}

.hamburgerHead:hover{
    background-position: -20px;
}

.hamburger-menu{
    position:absolute;
    top:0px;
    right:12px;
    width:160px;
    height:67px;
    
    background:#ffffff;
    font-family: "Muli";
    font-size: 14px;
    color: #000000;
    letter-spacing: 0px;
    border:solid 1px #000000;
    display:none;
}

#hamburgerMenu1{
    position:absolute;
    top:12px;
    left:20px;
    width:140px;
    height:18px;
}

.hamburger-menu-icon1{
    background: url('../img/add.png') no-repeat left;
}

#hamburgerMenu2{
    position:absolute;
    top:38px;
    left:20px;
    width:140px;
    height:18px; 
}

.hamburger-menu-icon2{
    background: url('../img/copy.png') no-repeat left;
}


.resultRow:nth-child(odd) {
    background-color:#f6f6f6;
}

.resultRow{
    width:100%;
    height:60px;
    font-family: "Muli";
    font-size: 12px;
    color: #000000;
    letter-spacing: 0;
    
}

.resultRow:hover {
    background-color:#dac9a1;
    
}

.resultRow td{
    text-align:center;
    padding:10px;
}

.resultRow td:nth-child(1){
    background-color:#ffffff;
}

.row-menu{
    
    width:30px;
    padding:20px 5px 30px 5px;
    line-height:0px;
    font-family: "Muli";
    font-size: 21px;
    color: #000000;
    letter-spacing: -1px;

}

.resultsCb{
    pointer-events: none;
    height:14px;
    width:14px;
    padding:6px 10px 6px 10px;

}

.checked{
    background:url('../img/check.png') no-repeat center;
}

.popup-menu{
    position:absolute;
    top:0;
    right:10px;
    width:110px;
    height:92px;
    
    background:#ffffff;
    font-family: "Muli";
    font-size: 14px;
    color: #000000;
    letter-spacing: 0px;
    border:solid 1px #000000;
    
    display:none;
}

#menu1{
    position:absolute;
    top:12px;
    left:20px;
    width:90px;
    height:18px;
}

.menu-icon1{
    background: url('../img/add.png') no-repeat left;
}

#menu2{
    position:absolute;
    top:38px;
    left:20px;
    width:90px;
    height:18px; 
}

.menu-icon2{
    background: url('../img/copy.png') no-repeat left;
}

#menu3{
    position:absolute;
    top:64px;
    left:20px;
    width:90px;
    height:18px;
}

.menu-icon3{
    background: url('../img/share.png') no-repeat left;
}

.menu-icon{
    position:absolute;
    width:14px;
    height:18px;
    overflow:hidden;
}

.menu-label{
    position:absolute;
    left:24px;
    height:18px;
}

.cart{
    position:absolute;
    right:0px;
    top:0px;
    /*width:25%;*/
    width:0%;
    height:75vh;
    overflow-y:scroll;
    overflow-x:none;
    -webkit-transition: width 0.5s;
    transition: width 0.5s;
}

.tableCart{
    width:97%;
}

.cartHeaderRow{
    height:30px;
    position:relative;
}

.cart-burger{
    background:url('../img/hamburger.png') no-repeat left;
    width:13px;
    height:14px;
    
    overflow:hidden;
    position:absolute;
    right:40px;
    top:8px;
    cursor:pointer;
}

.cart-burger:hover{
    background-position: -20px;
}

.hamburger-cart-menu{
    position:absolute;
    top:0px;
    right:0px;
    width:160px;
    /*height:119px;*/
    height:101px;
    background:#ffffff;
    font-family: "Muli";
    font-size: 14px;
    color: #000000;
    letter-spacing: 0px;
    border:solid 1px #000000;
    display:none;
}

#hamburgerCartMenu1{
    position:absolute;
    top:12px;
    left:20px;
    width:140px;
    height:18px;
}

.hamburger-cart-menu-icon1{
    background: url('../img/pdf.png') no-repeat left;
}

#hamburgerCartMenu2{
    position:absolute;
    top:38px;
    left:20px;
    width:140px;
    height:18px;
    display:none; 
}

.hamburger-cart-menu-icon2{
    background: url('../img/copy.png') no-repeat left;
}

#hamburgerCartMenu3{
    position:absolute;
    /*top:64px;*/
    top:38px;
    left:20px;
    width:140px;
    height:18px; 
}

.hamburger-cart-menu-icon3{
    background: url('../img/copy.png') no-repeat left;
}

#hamburgerCartMenu4{
    position:absolute;
    /*top:90px;*/
    top:64px;
    left:20px;
    width:140px;
    height:18px; 
}

.hamburger-cart-menu-icon4{
    background: url('../img/copy.png') no-repeat left;
}

.cart-trash{
    background:url('../img/trash.png') no-repeat left;
    width:11px;
    height:14px;
    
    overflow:hidden;
    position:absolute;
    right:15px;
    top:8px;
    cursor:pointer;
}

.download-pdf-btn-img{
    background:url('../img/pdf.png') no-repeat left;
    width:9px;
    height:14px;
    position:absolute;
    right:10px;
    top:2px;
    pointer-events:none;
}

.download-pdf-cancel-img{
    background:url('../img/x2.png') no-repeat left;
    width:8px;
    height:14px;
    position:absolute;
    right:10px;
    top:2px;
    pointer-events:none;
}

.cartHeaderRow td{
    text-align:center;
    font-family: "Muli";
    font-size: 13px;
    color: #000000;
    letter-spacing: 0;
    text-align:left;
    border-top: solid 1px #333333;
    border-bottom: solid 1px #333333;
}

.cartCell{
    position:relative;
    border-bottom: solid 1px #f2f2f2;
    width:100%;
    height:77px;
    padding: 10px;
}

.cart-pdf:hover, .cart-trash:hover {
    background-position:-20px center;
}

.removeItem{
    position:absolute;
    right:3px;
    top:5px;
    width:17px;
    height:17px;
    background: url('../img/x.png') no-repeat center;
    padding:5px;
    cursor:pointer;
}

.cart-item-wrapper{
    position:relative;
    min-height:77px;
    pointer-events:none;
}

.cart-position{
    font-family: "Lato";
    font-weight: 300;
    font-size: 8px;
    color: #4A4A4A;
    text-align:left;
    padding-bottom:5px;
}

.cart-name{
    font-family: "Lato";
    font-weight: 400;
    font-size: 13px;
    color: #000000;
    text-align:left;
    padding-bottom:5px;
}

.cart-phone{
    font-family: "Lato";
    font-weight: 400;
    font-size: 10px;
    color: #000000;
    text-align:left;
    padding-bottom:5px;
}

.cart-email{
    font-family: "Lato";
    font-weight: 400;
    font-size: 10px;
    color: #000000;
    text-align:left;
}

.prompt-wrapper{
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    display:none;
    opacity:0;
    -webkit-transition: opacity 1s;
    -moz-transition: opacity 1s;
    -o-transition: opacity 1s;
    transition: opacity 1s;
}

.prompt-bg{
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    background:#ffffff;
    opacity:0.6;
    z-index:100;
}

.prompt{
    position:relative;
    width:400px;
    height:160px;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    border:solid 1px #000000;
    background:#ffffff;
    z-index:101;
    
}

.pdf-text{
    position:absolute;
    top:50px;
    left:50%;
    transform: translateX(-50%);
    line-height:14px;
    font-size:14px;
    font-family: "Muli";
    color:#000000;
}

.pdf-name{
    position:absolute;
    width:300px;
    height:30px;
    top:40px;
    left:50%;
    transform: translateX(-50%);
    border-bottom:solid 1px #000000;
}

.pdf-name-form{
    position:absolute;
    width:300px;
    height:20px;
    top:6px;
    left:0;
}

.pdf-name-input{
    position:absolute;
    top:0;
    left:0;
    width:300px;
    height:20px;
    line-height:20px;
    font-size:14px;
    font-family: "Muli";
    color:#9b9b9b;
    border:none;
    background:none;
}

.download-pdf-validation{
    position:absolute;
    top:0;
    left:0;
    width:300px;
    height:8px;
    line-height:8px;
    font-size:8px;
    font-family: "Muli";
    color:#000000;
    top:73px;
    left:50%;
    padding-left:2px;
    transform: translateX(-50%);
}

.download-pdf-btn{
    position:absolute;
    top:99px;
    left:91px;
    width:122px;
    height:20px;
    border:solid 1px #000000;
    /*cursor:no-drop;
    opacity:0.3;*/
    cursor:pointer;
}
.download-pdf-btn-txt{
    position:absolute;
    top:5px;
    left:10px;
    line-height:10px;
    font-size:10px;
    font-family: "Muli";
    color:#000000;
    width:100%;
    height:12px;
    pointer-events:none;
}

.download-pdf-cancel{
    position:absolute;
    top:99px;
    left:233px;
    width:77px;
    height:20px;
    border:solid 1px #000000;
    cursor:pointer;
}

.download-pdf-cancel-txt{
    position:absolute;
    top:5px;
    left:10px;
    line-height:10px;
    font-size:10px;
    font-family: "Muli";
    color:#000000;
    width:100%;
    height:12px;
    pointer-events:none;
}

.delete-cart-text{
    position:absolute;
    top:50px;
    left:50%;
    transform: translateX(-50%);
    line-height:14px;
    font-size:14px;
    font-family: "Muli";
    color:#000000;
}

.delete-cart-btn{
    position:absolute;
    top:99px;
    left:111px;
    width:77px;
    height:20px;
    border:solid 1px #000000;
}

.delete-cart-btn-txt{
    position:absolute;
    top:5px;
    left:10px;
    line-height:10px;
    font-size:10px;
    font-family: "Muli";
    color:#000000;
    width:100%;
    height:12px;
    pointer-events:none;
}

.delete-cart-btn-img{
    background:url('../img/trash.png') no-repeat left;
    width:9px;
    height:14px;
    position:absolute;
    right:10px;
    top:2px;
    pointer-events:none;
}

.delete-cart-cancel{
    position:absolute;
    top:99px;
    left:213px;
    width:77px;
    height:20px;
    border:solid 1px #000000;
    cursor:pointer;
}

.delete-cart-cancel-txt{
    position:absolute;
    top:5px;
    left:10px;
    line-height:10px;
    font-size:10px;
    font-family: "Muli";
    color:#000000;
    width:100%;
    height:12px;
    pointer-events:none;
}

.delete-cart-cancel-img{
    background:url('../img/x2.png') no-repeat left;
    width:9px;
    height:14px;
    position:absolute;
    right:10px;
    top:2px;
    pointer-events:none;
}
